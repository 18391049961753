import { getRole } from './browser'
import { currentDateWithTimezone, timeConvertHHmm } from './date'
import addDays from 'date-fns/addDays'
import addMinutes from 'date-fns/addMinutes'
import addWeeks from 'date-fns/addWeeks'
import format from 'date-fns/format'
import getYear from 'date-fns/getYear'
import isBefore from 'date-fns/isBefore'
import startOfWeek from 'date-fns/startOfWeek'
import _, { isArray, isNumber } from 'lodash'
import { Operator, WEEK_DAY_INDEXES } from './constant'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { FLYER_GRADE, IELTS_GRADE, MOVERS_GRADE, STARTERS_GRADE } from './constant/grade'

/**
 * Hàm tính toán vị trí đặt tooltip dựa vào khoảng trống lớn hơn giữa trái hoặc phải, trên hoặc dưới
 * @param {Object} el - Object element DOM
 * @returns {String} - Trả về vị trí đặt tooltip của lib Antd
 */
export function getPlacementTooltip (el) {
  if (!el) {
    return 'left'
  }
  const x = window.innerWidth || document.documentElement.clientWidth
  const y = window.innerHeight || document.documentElement.clientHeight
  const rect = el.getBoundingClientRect()

  const dLeft = rect.left // distance left
  const dRight = x - rect.right // distance right
  const dAbove = rect.top // distance above
  const dBottom = y - rect.bottom // distance bottom

  let xPlace = 'left'
  let yPlace = 'top'

  if (dRight >= dLeft) {
    xPlace = 'right'
  }

  if (dAbove > dBottom) {
    yPlace = 'bottom'
  }

  return xPlace + _.upperFirst(yPlace)
}

export function copyTruthyValues (src, fields) {
  const des = {}
  if (!fields) {
    fields = Object.keys(src)
  }
  fields.forEach((key) => {
    if (src[key] || src[key] === 0) {
      des[key] = src[key]
    }
  })
  return des
}

/**
 * DEPRECATED. Chức năng không cần thiết.
 */
export function createUserOption (list) {
  if (!list || !list.length) return []
  return list?.map((user) => ({ value: user.id, label: user.userName }))
}

export function filterAutoTechnicalTicketTime (session, timeList) {
  const maxTime = getDate(session.date, session.time)
  const currentTime = currentDateWithTimezone()
  const today = format(maxTime, 'yyyyMMdd')
  const sessionDay = format(currentTime, 'yyyyMMdd')
  const isToday = today === sessionDay
  const todayMinTime = format(currentTime, 'HHmm')
  const sessionMaxTime = format(maxTime, 'HHmm')
  return timeList.filter((timeCodeOption) => {
    const time = timeCodeOption.value.split('_').slice(1).join('')
    if (isToday) {
      return time > todayMinTime && time < sessionMaxTime
    } else {
      return time < sessionMaxTime
    }
  })
}

export function filterFormToPayload (values) {
  const payload = {}
  Object.keys(values).forEach((key) => {
    const val = values[key]
    if (
      val === 0 ||
      (val && ((val instanceof Array && val.length) || !(val instanceof Array)))
    ) {
      payload[key] = values[key]
    }
  })
  return payload
}
/**
 * DEPRECATED - chuyển sang getDateV2 do data trả về từ backend server thay đổi
 */
export const getDate = (dateStr, timeStr) => {
  if (!dateStr || !timeStr) {
    return 0
  }
  dateStr = String(dateStr)
  const splitedTime = timeStr.split('_')
  if (typeof dateStr === 'number') dateStr = String(dateStr)
  const y = Number(dateStr.slice(0, 4))
  const m = Number(dateStr.slice(4, 6))
  const d = Number(dateStr.slice(6))
  const h = Number(splitedTime[1])
  const min = Number(splitedTime[2])
  return new Date(y, m - 1, d, h, min, 0)
}

/**
 *
 * @param {String | Number} dateStr - Time được trả về từ backend server có dạng YYYYmmdd. VD: 20221222
 * @param {String} timeStr - Giờ giấc dưới dạng hh:mm. VD: 09:00 (Có thể sử dụng hàm timeConvertHHmm để convert định dạng này)
 * @returns {Date} - Trả về Object Date
 */
export const getDateV2 = (dateStr, timeStr) => {
  if (!dateStr || !timeStr) {
    return 0
  }
  dateStr = String(dateStr)
  const splitedTime = timeStr.split(':')
  if (typeof dateStr === 'number') dateStr = String(dateStr)
  const y = Number(dateStr.slice(0, 4))
  const m = Number(dateStr.slice(4, 6))
  const d = Number(dateStr.slice(6))
  const h = Number(splitedTime[0])
  const min = Number(splitedTime[1])
  return new Date(y, m - 1, d, h, min, 0)
}

/**
 *
 * @param {String | Number} dateStr - Time được trả về từ backend server có dạng YYYYmmdd. VD: 20221222
 * @returns {String} - Ngày-tháng-năm được format theo dạng dd/mm/YYYY. VD: 22/12/2022
 */
export const getDateFormat = dateStr => {
  if (!dateStr) return null
  dateStr = String(dateStr)
  const y = dateStr.slice(0, 4)
  const m = dateStr.slice(4, 6)
  const d = dateStr.slice(6)
  return `${d}/${m}/${y}`
}

export function convertDate (date) {
  const dateUpdate = new Date(date).toLocaleDateString('en-US')
  const dateNew = dateUpdate.split('/')
  const arr = [dateNew[1], dateNew[0], dateNew[2]]
  return arr.join('/')
}

/**
 * DEPRECATED - data trả về từ backend server thay đổi
 */
export function getTimeFormat (timeCode) {
  if (!timeCode) return null
  return timeCode.split('_').slice(1).join(':')
}
export function getTimeDurationFormat (timeCode, duration = 45) {
  if (timeCode) {
    const dateStr = '20210101'
    const startTime = getDate(dateStr, timeCode)
    const endTime = addMinutes(startTime, duration)
    return `${format(startTime, 'HH:mm')} - ${format(endTime, 'HH:mm')}`
  }
  return ''
}

/**
 *
 * @param {String} day
 * - Ngày trong tuần. VD: MONDAY, SUNDAY, TUESDAY, WENESDAY...
 * @param {String} time
 * - Giờ giấc dưới dạng hh:mm. VD: 09:00 (Có thể sử dụng hàm timeConvertHHmm để convert định dạng này)
 * @param {Object} options
 * @param {Number} options.limit - Số lượng ngày gần nhất được tạo tự động
 * @param {Number} options.startAfter - ?????
 * @param {Number} options.weekOffset - ?????
 * @returns {Array} - Mảng ngày gần nhất có đinh dạng sau: [{ label: String, value: Number }]
 */
export function getNearestDayOptionsV2 (day, time, options = {}) {
  const { limit = 4, startAfter = null, weekOffset = 0 } = options
  const WeekDayIndex = WEEK_DAY_INDEXES
  const currentDate = startAfter
    ? addDays(getDateV2(startAfter, time).setHours(0, 0, 0, 0), 1)
    : currentDateWithTimezone()
  let firstDayOfFirstWeek = startOfWeek(currentDate, { weekStartsOn: 1 })
  if (weekOffset) {
    firstDayOfFirstWeek = addWeeks(firstDayOfFirstWeek, weekOffset)
  }
  let nearestDay = addDays(firstDayOfFirstWeek, WeekDayIndex[day])
  const [hour, minute] = time
    .split(':')
    .map(s => Number(s))
  nearestDay.setHours(hour, minute, 0, 0)
  if (isBefore(nearestDay, currentDate)) {
    nearestDay = addDays(nearestDay, 7)
  }
  const days = []
  for (let i = 0; i < limit; i++) {
    days.push(addDays(nearestDay, i * 7))
  }

  return days.map(day => ({
    label: format(day, 'dd/MM/yyyy'),
    value: Number(format(day, 'yyyyMMdd'))
  }))
}

/**
 * DEPRECATED - data từ backend server trả về thay đổi
 * Sử dụng hàm thay thế getNearestDayOptionsV2
 */
export function getNearestDayOptions (day, time, options = {}) {
  const { limit = 4, startAfter = null, weekOffset = 0 } = options
  const WeekDayIndex = WEEK_DAY_INDEXES
  const currentDate = startAfter
    ? addDays(getDateV2(startAfter, time).setHours(0, 0, 0, 0), 1)
    : currentDateWithTimezone()
  let firstDayOfFirstWeek = startOfWeek(currentDate, { weekStartsOn: 1 })
  if (weekOffset) {
    firstDayOfFirstWeek = addWeeks(firstDayOfFirstWeek, weekOffset)
  }
  let nearestDay = addDays(firstDayOfFirstWeek, WeekDayIndex[day])
  const [hour, minute] = time
    .split('_')
    .slice(1)
    .map((s) => Number(s))
  nearestDay.setHours(hour, minute, 0, 0)
  if (isBefore(nearestDay, currentDate)) {
    nearestDay = addDays(nearestDay, 7)
  }
  const days = []
  for (let i = 0; i < limit; i++) {
    days.push(addDays(nearestDay, i * 7))
  }

  return days.map((day) => ({
    label: format(day, 'dd/MM/yyyy'),
    value: Number(format(day, 'yyyyMMdd'))
  }))
}

export function getSearchQuery () {
  const params = new URLSearchParams(window.location.search)
  const queries = {}
  params.forEach((value, key) => {
    queries[key] = value
  })
  return queries
}
export function getFullName (user, options = { en: false }) {
  if (!user) return null
  const { firstName, middleName, lastName } = user
  const nameArr = options.en
    ? [lastName, ...(middleName?.split(' ') || []), firstName]
    : [firstName, ...(middleName?.split(' ') || []), lastName]
  return nameArr
    .filter((t) => String(t).toLowerCase() !== 'n/a')
    .reduce((acc, cur, idx, arr) => {
      if (cur) {
        acc = acc + _.capitalize(cur) + (idx < arr.length - 1 ? ' ' : '')
      }
      return acc
    }, '')
}

export function capitalizeAll (fullName) {
  if (!fullName) return fullName
  return fullName
    .split(/\s+/)
    .map((s) => _.capitalize(s))
    .join(' ')
}

export function getMatchLevel (levelOptions, level) {
  if (level) {
    const levelSlot = levelOptions.find((item) => item.value === level)?.level || 0
    levelOptions = levelOptions.filter((item) =>
      item.level === levelSlot - 1 || item.level === levelSlot + 1 || item.level === levelSlot
    )
  }
  return [...levelOptions]
}

export function getMatchLevelV2 (levelList, level) {
  const levelSlot = levelList.find((item) => item.code === level)?.value || 0
  const mathLevelList = levelList.filter((item) =>
    item.value === levelSlot - 1 || item.value === levelSlot + 1 || item.value === levelSlot
  )
  return mathLevelList?.map(levelValue => levelValue.code)
}

export function hasIntersection (arr1, arr2) {
  return arr1.some((item) => arr2.includes(item))
}

export function hasRequiredItem (userItems, requiredItems) {
  if (!requiredItems || !userItems) {
    return false
  }
  if (requiredItems instanceof Array && requiredItems.length === 0) {
    return true
  }
  return userItems.some((item) => requiredItems.includes(item))
}

/**
 * Tạo mặt nạ (****) cho 4 số cuối của số điện thoại với user role là gia sư
 * @param {String} phone - Số điện thoại
 * @returns {String}
 * Ví dụ:
 * Nếu user role là gia sư số điện thoại sẽ được tạo mặt nạ: 091234567890 --> 09123456****
 * Nếu user role không phải gia sư: 091234567890 --> 091234567890
 */
export function hidePhone (phone) {
  const currentRole = getRole()
  const numberOfRoles = currentRole.length
  const employeeTeacherRole = currentRole.includes('employee-teacher')
  if (numberOfRoles === 1 && employeeTeacherRole) {
    return phone?.replace?.(phone.slice(phone.length - 4), '*'.repeat(4))
  }
  return phone
}
export function hidePhoneNotRole (phone) {
  return phone?.replace?.(phone.slice(phone.length - 4), '*'.repeat(4))
}

/**
 * DEPRECATED - Hàm xử lý lỗi do getDateFormat trả về Date String
 * Hàm kiểm tra thời điểm (date) là quá khứ hay tương lai
 * @param {Object} obj
 * @param {String | Number} obj.scheduleDate - Time được trả về từ backend server có dạng YYYYmmdd. VD: 20221222
 * @returns {Boolean} - True là thời điểm trong quá khứ, False là thời điểm trong tương lai
 */
export function isPastTime (obj) {
  try {
    const { scheduleDate } = obj
    const targetTime = getDateFormat(scheduleDate)
    const currentTime = currentDateWithTimezone()
    return isBefore(targetTime, currentTime)
  } catch {}
}

/**
 * Hàm kiểm tra thời điểm (date) là quá khứ hay tương lai
 * @param {Object} obj
 * @param {String | Number} obj.date - Time được trả về từ backend server có dạng YYYYmmdd. VD: 20221222
 * @param {Number} obj.time - Time được trả về từ backend server là giờ giấc trong ngày hệ quy đổi phút. VD: 540 (9h sáng), 600 (10h sáng) ...
 * @returns {Boolean} - True là thời điểm trong quá khứ, False là thời điểm trong tương lai
 */
export function isPastTimeV2 (obj) {
  try {
    const { date, time } = obj
    const targetTime = getDateV2(date, timeConvertHHmm(time))
    const currentTime = currentDateWithTimezone()
    return isBefore(targetTime, currentTime)
  } catch (err) {
    return true
  }
}

export function isPastTimeV3 (learnDateTimeClose, TimeServer) {
  const targetTime = new Date(TimeServer)
  return isBefore(learnDateTimeClose, targetTime)
}

/**
 * DEPRECATED - data từ backend server thay đổi
 */
export function studentlearnIndexAndStarttime (classData, studentId) {
  const student = classData?.students.find((std) => std.id === studentId)
  if (!student) return {}
  const { startTime } = student
  return {
    openDate: startTime && getDateFormat(startTime),
    openDateValue: startTime
  }
}

/**
 * Tạo mảng options năm sinh bắt đầu từ 1980 --> hiện tại.
 * @returns {Array} - Mảng option năm sinh có cấu trúc:
 * [
 *    {
 *      value: String,
 *      label: String
 *    }, { ... }
 * ]
 */
export function getYearOfBirthOptions () {
  const minYear = 1980
  const currentYear = getYear(Date.now())
  const maxYear = currentYear - 14

  let year = minYear
  const options = []
  do {
    const strY = String(year)
    options.push({
      value: strY,
      label: strY
    })
    year++
  } while (year <= maxYear)
  return options
}

export function teacherGradeToGrade (teacherGrades) {
  if (!teacherGrades || !teacherGrades.length) return
  let grade = []
  if (teacherGrades.includes('Starters')) {
    grade = grade.concat(STARTERS_GRADE)
  }
  if (teacherGrades.includes('Movers')) {
    grade = grade.concat(MOVERS_GRADE)
  }
  if (teacherGrades.includes('Flyers')) {
    grade = grade.concat(FLYER_GRADE)
  }
  if (teacherGrades.includes('Ielts')) {
    grade = grade.concat(IELTS_GRADE)
  }
  return grade
}

export function gradeToTeacherGrade (grades) {
  if (!grades || !grades.length) return
  let teacherGrade = []
  const starterGrades = STARTERS_GRADE
  const MoverGrades = MOVERS_GRADE
  const FlyerGrades = FLYER_GRADE
  const IeltsGrades = IELTS_GRADE
  if (starterGrades.every((item) => grades.includes(item))) {
    teacherGrade = teacherGrade.concat('Starters')
  }
  if (MoverGrades.every((item) => grades.includes(item))) {
    teacherGrade = teacherGrade.concat('Movers')
  }
  if (FlyerGrades.every((item) => grades.includes(item))) {
    teacherGrade = teacherGrade.concat('Flyers')
  }
  if (IeltsGrades.every((item) => grades.includes(item))) {
    teacherGrade = teacherGrade.concat('Ielts')
  }
  return teacherGrade
}

/**
 * Hàm tách fullName thành object name gồm: Họ, Tên, Đệm, FullName
 * @param {String} fullName
 * @returns {Object} - Dữ liệu trả về có dạng { fullName: String, firstName: String, lastName: String, middleName: String }
 */
export function splitName (fullName) {
  fullName = fullName.replace(/\s+/g, ' ')
  fullName = _.capitalize(fullName)
  fullName = _.trim(fullName, ' ')
  const partials = fullName.split(/\s/g)
  const len = partials.length
  const lastName = (len && partials[len - 1]) || ''
  const firstName = (len >= 2 && partials[0]) || ''
  const middleName = (len >= 3 && partials.slice(1, len - 1).join(' ')) || ''
  return { fullName, firstName, lastName, middleName }
}

/**
 * Hàm convert giây --> phút
 * @param {Number} totalSecond - Số giây cần convert
 * @returns {String} - Số phút trả về được làm tròn đến số nguyên (không tính sau phần dấu phẩy). VD: 90s --> 1p
 */
export function totalMinuteOfLearning (totalSecond) {
  if (typeof totalSecond !== 'number') return 'N/A'
  return (totalSecond / 60).toFixed(0)
}

/**
 * DEPRECATED - data từ server backend trả về thay đổi
 */
export function dayTimeOfOccurrence (data) {
  if (!data || !data.occurrences || !data.occurrences.length) {
    return { day: undefined, time: undefined }
  }
  return data.occurrences[0]
}

/**
 * Lấy property "comment" cuối cùng của mảng notes.
 * @param {Array} notes - Mảng notes lấy từ data backend trả về
 * @returns {String}
 */
export function lastComment (notes) {
  if (!notes || !notes.length) {
    return ''
  }
  return notes[notes.length - 1].comment
}

export function toJson (input) {
  return JSON.stringify(input, null, '\t')
}
export function formatWithTimezone (timestamp, formatString, timezone = 7) {
  if (typeof timestamp !== 'number') {
    console.warn(`check input ${timestamp}`)
  }
  const date = new Date(timestamp)
  const dateValue = date.getTime()
  const dateWithTimezone =
    dateValue +
    date.getTimezoneOffset() * 60 * 1000 +
    timezone * 60 * 60 * 1000
  return format(dateWithTimezone, formatString)
}

/**
 * Chuyển đổi giờ giấc trong ngày sang phút
 * @param {Object} time - Đối tượng time moment. Refer: https://momentjs.com/docs/
 * @returns {Number} - Số phút được chuyển đổi theo giờ giấc trong ngày.
 * VD: Đổi 9h30 sáng --> 570.
 *     Đổi 21h30 (9h30 chiều) --> 1290.
 */
export function getMinutesFromMoment (time) {
  if (!time) return 0
  return time.hours() * 60 + time.minutes()
}

/**
 * DEPRECATED. Trùng chức năng với createRangeTimeOptions trong libs/date/createRangeTimeOptions
 */
export function addTimeLabelV2 (timeList) {
  return _.uniqBy(timeList, 'classTimeSlotId')?.map((item) => {
    const { startTime, endTime, classTimeSlotId } = item
    const label = [timeConvertHHmm(startTime), timeConvertHHmm(endTime)].join(' - ')
    return {
      label,
      value: classTimeSlotId
    }
  })
}
export function convertToCriteriaWithCommonOperator (
  criteriaSchema,
  formValues
) {
  const criteria = []
  Object.keys(criteriaSchema).forEach((op) => {
    const fields = _.get(criteriaSchema, op)
    if (fields) {
      if (
        op === Operator.EQUAL ||
        op === Operator.LIKE ||
        op === Operator.IN ||
        op === Operator.NOT_IN ||
        op === Operator.LESS_THAN_OR_EQUAL ||
        op === Operator.GREATER_THAN_OR_EQUAL ||
        op === Operator.EQUAL_CASE_INSENSITIVE ||
        op === Operator.BETWEEN ||
        op === Operator.LIKE_IGNORE_CASE
      ) {
        fields.forEach((item) => {
          const isStringItem = typeof item === 'string'
          const field = (isStringItem ? item : item.field).replaceAll('_', '.')
          const operator = op
          const criteriaItem = {
            field,
            operator
          }

          let fieldValue = _.get(formValues, item)

          if (fieldValue?.value) {
            fieldValue = fieldValue.value
          }
          if (fieldValue instanceof Array && fieldValue[0]?.value) {
            fieldValue = fieldValue.map((v) => v.value)
          }

          if (op === Operator.IN || op === Operator.NOT_IN) {
            const values = fieldValue
            if (
              values instanceof Array &&
              values.length &&
              !values.includes('ALL')
            ) {
              criteriaItem.values = values
              if (values.some((val) => typeof val === 'boolean')) {
                criteriaItem.type = 'Boolean'
              } else if (values.some((val) => typeof val === 'number')) {
                criteriaItem.type = 'Int'
              }
            }
          } else if (
            op === Operator.EQUAL ||
            op === Operator.LIKE ||
            op === Operator.LESS_THAN_OR_EQUAL ||
            op === Operator.GREATER_THAN_OR_EQUAL ||
            op === Operator.EQUAL_CASE_INSENSITIVE ||
            op === Operator.LIKE_IGNORE_CASE
          ) {
            let value = fieldValue
            if (typeof value === 'string') {
              value = value.trim()
            }
            if (
              ((value === 0 || value) && value !== 'ALL') ||
              typeof value === 'boolean'
            ) {
              criteriaItem.value = value
              if (typeof value === 'boolean') {
                criteriaItem.type = 'Boolean'
              } else if (typeof value === 'number') {
                criteriaItem.type = 'Int'
              }
            }
          } else if (op === Operator.BETWEEN && !isStringItem) {
            const { minField, maxField } = item
            const min = formValues[minField]
            const max = formValues[maxField]
            const minIsNotEmpty = !!(min === 0 || min)
            const maxIsNotEmpty = !!(max === 0 || max)
            if (minIsNotEmpty && maxIsNotEmpty) {
              criteriaItem.operator = Operator.BETWEEN
              criteriaItem.min = min
              criteriaItem.max = max
            } else if (minIsNotEmpty) {
              criteriaItem.operator = Operator.GREATER_THAN_OR_EQUAL
              criteriaItem.value = min
            } else if (maxIsNotEmpty) {
              criteriaItem.operator = Operator.LESS_THAN_OR_EQUAL
              criteriaItem.value = max
            }
          }

          if (Object.keys(criteriaItem).length > 2) {
            !isStringItem && item.type && (criteriaItem.type = item.type)
            criteria.push(criteriaItem)
          }
        })
      } else {
        console.log(op)
        throw new Error('schema operator is not defined')
      }
    }
  })
  return criteria
}

export function checkBusySession (studentSessionCredits, studyProgramId) {
  const getSessionCreditsSortNew = (sessionCredit) => {
    return sessionCredit?.studentOrders?.sort((a,b) => a?.orderId - b?.orderId)
  }

  const getStudyProgramPackage = (studyPrograms) => {
    return studyPrograms?.find(val => val?.studyProgramId === studyProgramId)?.main?.credit
  }

  return studentSessionCredits.every((sessionCredit) => {
    const programOrder = sessionCredit?.programs?.find(credit => credit?.studyProgramId === studyProgramId)
    const currrentPackage = sessionCredit?.studentOrders?.find(order => order?.orderId === programOrder?.effectiveOrderId)

    const sessionCreditsSortNew = getSessionCreditsSortNew(sessionCredit)

    let checkBusySession = false
    
    const currentStudyProgram = currrentPackage?.studyPrograms?.find(item => item?.studyProgramId === studyProgramId)
    if (currrentPackage?.busy?.credit > 0){
      if (currentStudyProgram?.main?.credit > 0){
        checkBusySession = true
      }
    }
    else if (currentStudyProgram?.main?.credit === 0) {
      const nextPackageActive = sessionCreditsSortNew?.find(item => item?.orderId > currrentPackage?.orderId && getStudyProgramPackage(item?.studyPrograms) > 0)
      if (nextPackageActive?.busy?.credit){
        const nextStudyProgram = nextPackageActive?.studyPrograms?.find(item => item?.studyProgramId === studyProgramId)
        if (nextStudyProgram?.main?.credit > 0){
          checkBusySession = true
        }
      }
    }
    return checkBusySession
  })
}

export async function exportAsPdf(element, marginOfSafely = 40, imageFileName) {
   
	const canvas = await html2canvas(element, { scale: 1 })
	const imgWidth = 210
	let pageHeight = 297

  let pageMarginOfSafely = [40, 40]

  if (isNumber(marginOfSafely)) {
    pageMarginOfSafely = [marginOfSafely, marginOfSafely]
  } else if (
    isArray(marginOfSafely) 
      && marginOfSafely.length === 2 
      && isNumber(marginOfSafely[0]) 
      && isNumber(marginOfSafely[1])
  ) {
    pageMarginOfSafely = marginOfSafely
  }

  const innerPageWidth = imgWidth - pageMarginOfSafely[0];
  const innerPageHeight = pageHeight - pageMarginOfSafely[1];

   // Calculate the number of pages.
    
  const pxPageHeight = Math.floor(canvas.width * (innerPageHeight / innerPageWidth))

  const objPages = [{ page: 1, height: 0 }]
  const fixedBlockElems = element.getElementsByClassName('fixed-block')

  if (!fixedBlockElems.length) throw Error('Cannot find element fixed-block in document')

  for (let fixedBlockIndex = 0; fixedBlockIndex < fixedBlockElems.length; fixedBlockIndex++) {
    const fixedBlockElemHeight = fixedBlockElems[fixedBlockIndex].clientHeight
    
    const lastObjPage = objPages.slice(-1)[0]
    if (lastObjPage.height + fixedBlockElemHeight < pxPageHeight) {
      objPages[objPages.length - 1] = { ...lastObjPage, height: lastObjPage.height + fixedBlockElemHeight }
    } else {
      objPages.push({ page: objPages.length + 1, height: fixedBlockElemHeight })
    }
  }

  // Create a one-page canvas to split up the full image.
  const pageCanvas = document.createElement('canvas');
  const pageCtx = pageCanvas.getContext('2d');
  pageCanvas.width = canvas.width;
  pageCanvas.height = pxPageHeight;


  const doc = new jsPDF('p', 'mm', 'a4')

  for (let pageIndex = 0; pageIndex < objPages.length; pageIndex++) {
    
    const yAxis = objPages.reduce((total, objPage, index) => {
      if (index < pageIndex) {
        return total + objPage.height
      }
      return total
    }, 0)

    // Display the page.    
    var w = pageCanvas.width;
    var h = pageCanvas.height;
    pageCtx.fillStyle = 'white';
    pageCtx.fillRect(0, 0, w, h);
    pageCtx.drawImage(canvas, 0, yAxis, canvas.width, objPages[pageIndex].height, 0, 0, w, objPages[pageIndex].height);
        
    // Add the page to the PDF.
    if (pageIndex > 0) doc.addPage();
    const imgData = pageCanvas.toDataURL('image/png', 0.98);
  
    doc.addImage(imgData, 'image/png', Math.floor(pageMarginOfSafely[0] / 2), Math.floor(pageMarginOfSafely[1] / 2), innerPageWidth, innerPageHeight);
  }

  doc.save(`${imageFileName}.pdf`)
}

export function dateRangeOverlaps(aStart, aEnd, bStart, bEnd) {
  if (aStart <= bStart && bStart <= aEnd) return true;
  if (aStart <= bEnd   && bEnd   <= aEnd) return true;
  if (bStart <  aStart && aEnd   <  bEnd) return true;
  return false;
}

export async function exportAsImage(element, imageFileName) {
  const canvas = await html2canvas(element)

  const pageCanvas = document.createElement('canvas');
  const pageCtx = pageCanvas.getContext('2d');
  pageCanvas.width = canvas.width + 80;
  pageCanvas.height = canvas.height + 80;

  var w = pageCanvas.width;
  var h = pageCanvas.height;
  pageCtx.fillStyle = 'white';
  pageCtx.fillRect(0, 0, w, h);
  pageCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 40, 40, canvas.width, canvas.height);

  const image = pageCanvas.toDataURL('image/png', 1.0)
  downloadImage(image, imageFileName)
}

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement('a')
  fakeLink.style = 'display:none;'
  fakeLink.download = fileName

  fakeLink.href = blob

  document.body.appendChild(fakeLink)
  fakeLink.click()
  document.body.removeChild(fakeLink)

  fakeLink.remove()
}
export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export function getUserInfoData (userInfoData, shortName) {
  return userInfoData?.find(item => item.field.shortName === shortName)?.data
}

export function formatMoneyVnd (number, currency, options) {
  const sign = number < 0 ? '-' : ""
  const NumberAbs = Math.abs(number)
  return sign + String(NumberAbs).split('').reverse().reduce((prev, next, index) => {
    return ((index % 3) ? next : (next + '.')) + prev
  }) + ` ${options?.symbol || currency}`
}

/**
 * 
 * @param {Number} number - số tiền 
 * @param {String} currency - đơn vị tiền. VD: VND, USD, EUR...
 * @param {Object} options
 * @param {String} options.symbol - kí hiệu tiền. VĐ: đ, $, ...
 * @returns 
 */
export function formatMoney(number, currency, options = { }) {
  switch(currency) {
    case 'VND' :
      return formatMoneyVnd(number, currency, options)
    default:
      return () => {}
  }
}